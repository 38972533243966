<template>
  <div>in maintenance....</div>
</template>


<script>

export default {
  name: 'App',

  beforeCreate() {
    const currentUrl = window.location.href;
    if (currentUrl.startsWith("https://www.margusengso.com/taudio")) {
      window.location.href = currentUrl.replace("https://www.margusengso.com", "https://margusengso.com");
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
